.header {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 14px;
}

.header .input {
  margin-right: 8px;
}

.header .secondary-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding-left: 18px;
  padding-top: 2px;
}
