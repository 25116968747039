body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/*coremirror component overrides:*/
.react-codemirror2 {
  height: inherit;
}

.CodeMirror {
  height: inherit;
}

.CodeMirror pre.CodeMirror-placeholder {
  color: #00000061;
}

.CodeMirror pre .cm-positive {
  color: black;
  background-color: lightgreen;
}

.CodeMirror pre .cm-negative {
  color: white;
  background-color: #ff4444;
}

html,
body,
#root,
.root {
  height: 100%;
}

.container {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 600px) {
  .container {
    min-height: calc(100vh - 56px);
  }
}

@media screen and (min-width: 600px) {
  .container {
    min-height: calc(100vh - 64px);
  }
}

.container .main-container {
  flex: 1;
  padding: 20px;
}
