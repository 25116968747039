.running {
  color: lightgreen;
}

.stopped {
  color: #ff4444;
}

.pending {
  color: orange;
}

.icon-container {
  display: inline-grid;
  font-size: 1rem;
  justify-items: center;
  align-items: center;
  height: 24px;
  width: 24px;
}

.icon {
  margin: auto;
}

.large {
  height: 24px;
  width: 24px;
}

.medium {
  height: 18px;
  width: 18px;
}

.small {
  height: 14px;
  width: 14px;
}

.pointer {
  cursor: pointer;
}
