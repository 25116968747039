.search-input {
  max-height: 36px; /*Std button height*/
}

.search-input .search-icon {
  color: grey;
  margin-left: -4px;
}

.search-input input {
  margin-top: 2px;  
}