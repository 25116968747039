.drawer-header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px 20px 20px 24px;
}

.drawer-header-container p {
  margin-top: 2px;
}

.drawer-header-container .close-button {
  cursor: pointer;
  margin-left: auto;
}